import React from "react";

const Terms = () => {
  return (
    <section class="terms-page-section p-tb-100">
      <div class="container">
        <div class="terms-privacy">
          <h3 class="sub-section-title">Our Terms & Conditions</h3>
          <ul class="terms-privacy-list">
            <li>
              <p>
                <strong>01.</strong>Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Ab, tempora laboriosam porro fugit debitis,
                dolorem natus assumenda facilis in ut reiciendis laborum
                sapiente minima libero quas praesentium ex rerum eos. Lorem
                ipsum dolor sit amet consectetur adipisicing elit. Voluptates
                nulla porro commodi.
              </p>
            </li>
            <li>
              <p>
                <strong>02.</strong>Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Ab, tempora laboriosam porro fugit debitis,
                dolorem natus assumenda facilis in ut reiciendis laborum
                sapiente minima libero quas praesentium ex rerum eos. Lorem
                ipsum dolor sit amet consectetur adipisicing elit. Voluptates
                nulla porro commodi.
              </p>
            </li>
            <li>
              <p>
                <strong>03.</strong>Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Ab, tempora laboriosam porro fugit debitis,
                dolorem natus assumenda facilis in ut reiciendis laborum
                sapiente minima libero quas praesentium ex rerum eos. Lorem
                ipsum dolor sit amet consectetur adipisicing elit. Voluptates
                nulla porro commodi.
              </p>
            </li>
            <li>
              <p>
                <strong>04.</strong>Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Ab, tempora laboriosam porro fugit debitis,
                dolorem natus assumenda facilis in ut reiciendis laborum
                sapiente minima libero quas praesentium ex rerum eos. Lorem
                ipsum dolor sit amet consectetur adipisicing elit. Voluptates
                nulla porro commodi.
              </p>
            </li>
            <li>
              <p>
                <strong>05.</strong>Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Ab, tempora laboriosam porro fugit debitis,
                dolorem natus assumenda facilis in ut reiciendis laborum
                sapiente minima libero quas praesentium ex rerum eos. Lorem
                ipsum dolor sit amet consectetur adipisicing elit. Voluptates
                nulla porro commodi.
              </p>
            </li>
          </ul>
          <h3 class="sub-section-title">Changes to the Terms</h3>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
          <h3 class="sub-section-title">Additional Terms</h3>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Terms;
